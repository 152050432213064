import { Link } from "gatsby"
import React from "react"
import { Container } from "../../components"
import Seo from "../../components/seo"

const SuccessPage = () => {
  return (
    <Container>
      <Seo title="【完了】お問い合わせありがとうございました。" />
      <section className="bg-white px-6 py-16 md:px-8 lg:px-0">
        <div className="max-w-2xl mx-auto">
          <div className="mb-8">
            <p>
              この度はお問い合わせメールをお送りいただきありがとうございます。
              <br />
              後ほど、担当者よりご連絡をさせていただきます。
              <br />
              今しばらくお待ちくださいますようよろしくお願い申し上げます。
              <br />
              <br />
              なお、しばらくたっても当院より返信、返答がない場合は、
              <br />
              お客様によりご入力いただいたメールアドレスに誤りがある場合がございます。
              <br />
              その際は、お手数ですが再度送信いただくか、
              <br />
              お電話（03-6450-4531）にてご連絡いただけますと幸いです。
              <br />
            </p>
          </div>
          <div className="w-full flex items-center justify-center">
            <Link
              to="/"
              className="w-fit-content bg-primary-gold text-white border-primary-gold border-2 py-2 px-8 rounded-full text-sm md:text-base md:px-16 md:py-4 hover:bg-white transform duration-300 hover:text-primary-gold"
            >
              HOMEへ戻る
            </Link>
          </div>
        </div>
      </section>
    </Container>
  )
}

export default SuccessPage
